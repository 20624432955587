export enum AUTHEN_REDUX_CONSTANTS {
    SET_PROGRESS = 'SET_PROGRESS',

};

export enum PROFILE_REDUX_CONSTANTS {
    PROFILE_DETAILS = 'PROFILE_DETAILS'
}

export enum POST_REDUX_CONSTANTS {
    GET_POSTS_DETAILS = 'GET_POSTS_DETAILS'
}

export enum ROOT_REDUX_CONSTANTS {
    RESET_STORE = 'RESET_STORE'
}